import { Box, Heading, Link, Text } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"
import MainHeaderBar from "../components/atom/main-header-bar"
import Layout from "../components/layout"
import HatenaArticles from "../components/organisms/hatena-articles"
import Profile from "../components/organisms/profile"
import Search from "../components/organisms/search"
import Seo from "../components/seo"

const PrivacyPoclicy = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata?.title || `Title`

  const sideBarContents = [<Profile />, <HatenaArticles />]
  return (
    <Layout
      location={location}
      title={siteTitle}
      sideBarContents={sideBarContents}
    >
      <Seo title="プライバシーポリシー"></Seo>
      <MainHeaderBar>プライバシーポリシー</MainHeaderBar>
      <Box className="blog-post">
        <Heading as="h2">
          当サイトが使用しているアクセス解析ツールについて
        </Heading>
        <Text>
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        </Text>
        <Text>
          このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
        </Text>
        <Text>
          このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
        </Text>
        <Text>
          この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
        </Text>
        <Text>
          この規約に関して、詳しくは
          <a href="https://marketingplatform.google.com/about/analytics/terms/jp/">
            こちら
          </a>
          をご覧ください。
        </Text>
      </Box>
    </Layout>
  )
}

export default PrivacyPoclicy
